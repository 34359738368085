<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="collapse navbar-collapse">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="mobileMenuSidebarOpen($event, 'overlay-open')" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="float-start navbar-nav navbar-left">
      </ul>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons">
            <app-feather-icons [icon]="'bell'" [class]="'header-icon'" (click)="loadNotifications()"></app-feather-icons>
            <span *ngIf="unreadCount > 0" class="notification-badge">{{ unreadCount }}</span>
          </button>
          <mat-menu #notificationMenu="matMenu" class="nfc-menu">
            <div class="nfc-header">
              <h5 class="mb-0">Notificaciones</h5>
              <!-- <a class="nfc-mark-as-read">Mark all as read</a> -->
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div>
                      <button mat-menu-item *ngFor="let notification of notifications"
                        (click)="readNotification(notification)" [ngClass]="[notification.status]">
                        <span class="table-img msg-user ">
                          <i class="material-icons-two-tone nfc-type-icon"
                            [ngClass]="[notification.color]">{{notification.icon}}</i>
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{notification.message}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i> {{notification.time}}
                          </span>
                        </span>
                        <!-- <span class="nfc-close">
                          <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                        </span> -->
                      </button>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <!-- <div class="nfc-footer">
              <a class="nfc-read-all">Read
                All Notifications</a>
            </div> -->
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <span>{{ userLogged?.displayName }}</span>
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button (click)="perfil()" mat-menu-item>
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Perfil
                  </button>
                  <!-- <button mat-menu-item>
                    <app-feather-icons
                      [icon]="'mail'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Inbox
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons
                      [icon]="'settings'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Settings
                  </button> -->
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->

      </ul>
    </div>
  </div>
</nav>
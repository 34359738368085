import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@config';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { InConfiguration, AuthService, LanguageService } from '@core';
import { myService } from 'app/projects-list/utils/reset.service';
import { lastValueFrom } from 'rxjs';
import * as moment from 'moment';
import { APIService } from '@shared/services/api.service';

interface Notifications {
  message: string;
  time: string;
  icon: string;
  color: string;
  status: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  public config!: InConfiguration;
  userImg?: string;
  homePage?: string;
  isNavbarCollapsed = true;
  flagvalue: string | string[] | undefined;
  countryName: string | string[] = [];
  langStoreValue?: string;
  defaultFlag?: string;
  isOpenSidebar?: boolean;
  docElement: HTMLElement | undefined;
  isFullScreen = false;
  userLogged: any;
  userId: string;
  unreadCount: number = 0;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private apiService: APIService,
    public languageService: LanguageService,
    private myService: myService
  ) {
    super();

    const user = localStorage.getItem('currentUser');
    this.userLogged = JSON.parse(localStorage['currentUser']);
    this.userId = this.userLogged.id
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  ];
  notifications: Notifications[] = [];

  escuchaMensajes() {
    this.myService.escucha().subscribe((data) => {
      // console.log(data);
    })
  }

  readNotification(notification: any) {
    notification.color = 'nfc-green';
    notification.status = 'msg-read';
    this.unreadCount--;
    this.apiService.updateNotification(notification.id).subscribe((data: any) => {
      this.router.navigateByUrl(notification.url);
    })
  }

  ngOnInit() {
    this.escuchaMensajes();
    this.config = this.configService.configData;
    this.userImg = this.authService.currentUserValue.img;

    this.homePage = 'dashboard';

    this.langStoreValue = localStorage.getItem('lang') as string;
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.loadNotifications();

    setInterval(() => {
      this.loadNotifications();
    }, 10000)

  }

  async loadNotifications() {
    const user = JSON.parse(localStorage['currentUser']);
    this.authService.getNotifications(user.id).subscribe((result) => {
      this.unreadCount = result.Data.unreadCount;
      if (result.Data.notifications) {
        this.notifications = result.Data.notifications.map((notification: any) => {
          return {
            id: notification.id,
            url: notification.url,
            message: notification.message,
            time: moment(notification.created_at).format("DD/MM/Y hh:mm"),
            icon: 'mail',
            color: notification.isRead ? 'nfc-green' : 'nfc-red',
            status: notification.isRead ? 'msg-read' : 'msg-unread',
          }
        });
      }
    });
  }

  callFullscreen() {
    if (!this.isFullScreen) {
      this.docElement?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className,
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }

    const hasClass2 = this.document.body.classList.contains('side-closed');
    if (hasClass2) {
      // this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      // this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }

  perfil() {
    this.router.navigate([`/profile/${this.userId}`]);
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { ActivateComponent } from './authentication/activate/activate.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'payments',
        loadChildren: () =>
          import('./payments-list/payments-list.module').then((m) => m.PaymentsListModule),
      },
      {
        path: 'almacen',
        loadChildren: () =>
          import('./almacen/almacen.module').then((m) => m.AlmacenModule),
      },
      {
        path: 'taller',
        loadChildren: () =>
          import('./taller/taller.module').then((m) => m.TallerModule),
      },
      {
        path: 'operations',
        loadChildren: () =>
          import('./operations/operations.module').then((m) => m.OperationsModule),
      },
      {
        path: 'requisitions',
        loadChildren: () =>
          import('./purchases/purchases.module').then((m) => m.PurchasesModule),
      },
      {
        path: 'purchases',
        loadChildren: () =>
          import('./purchases/purchases.module').then((m) => m.PurchasesModule),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'accounts-payable',
        loadChildren: () =>
          import('./accounts-payable/accounts-payable.module').then((m) => m.AccountsPayableModule),
      },
      {
        path: 'accounts-receivable',
        loadChildren: () =>
          import('./accounts-receivable/accounts-receivable.module').then((m) => m.AccountsReceivableModule),
      },
      {
        path: 'catalogs',
        loadChildren: () =>
          import('./catalogs/catalogs.module').then((m) => m.CatalogsModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'torre-control',
        loadChildren: () =>
          import('./torre-control/torre-control.module').then((m) => m.TorreControlModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports-routing.module').then(
            (m) => m.ReportsRoutingModule,
          ),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule,
          ),
      },
      {
        path: 'suppliers',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then(
            (m) => m.UsersModule,
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects-list/projects-list.module').then(
            (m) => m.ProjectsListModule,
          ),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule,
          ),
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule,
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: 'survey',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

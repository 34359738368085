import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment.development';
import { getHeaders } from '@core/utils/headers.functions';
import { PaginatedProducts, ServiceOrderFilters, ServiceOrderResponse } from '@shared/interfaces';
const apiURL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
    })
    export class APIService {
    getAccountsPayable(page: number = 1, limit: number = 10): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('limit', limit.toString());
    
        return this.http.get<any>(`${apiURL}accounts-payable`, { params });
    }
    registerPayment(id: any, payment: any): Observable<any> {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}accounts-receivable/${id}/payments`, payment, { headers });
    }
    getAccountsByServiceOrder(id: any): Observable<any> {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}accounts-receivable/service-order/${id}`, { headers });
    }
    getChecklistData(id: string | null): Observable<any> {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}/checklist`, { headers });
    //   throw new Error('Method not implemented.');
    }
    saveFile(item: any, id: number) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}/service-order/${id}/files`, item, { headers });
    }
    getValidateFeatures(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}validate-features/${id}`, { headers });
    }
    updateAnswers(id: any, answers: any[]) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order/${id}/inventario`, { answers }, { headers });
    }
    createPurchaseOrder(body: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}purchase-orders`, body, { headers });
    }

    getLowStockProducts() {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}validate-min-stock`, { headers });
    }

    updateServiceItem(id: any, body: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order-item/${id}`, body, { headers });
    }
    getListActivities(filters: ServiceOrderFilters): Observable<ServiceOrderResponse> {
        // Construir parámetros de consulta
        let params = new HttpParams();

        if (filters.orderId) {
            params = params.set('orderId', filters.orderId);
        }
        if (filters.date) {
            params = params.set('date', filters.date);
        }
        if (filters.status) {
            params = params.set('status', filters.status);
        }
        if (filters.userId) {
            params = params.set('userId', filters.userId);
        }

        return this.http.get<ServiceOrderResponse>(`${apiURL}service-activities`, { params });
    }
    getItemsById(body: any) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}service-items/${body}`, { headers });
    }

    getInventoryMovements(type?: string) {
        let url = `${apiURL}inventory-movements`;
        if (type) {
            url += `?type=${type}`;
        }
        return this.http.get<any>(url);
    }

    getAvailableStock(value: any) {
        return this.http.get<any>(`${apiURL}available-stock/${value}`);
        // throw new Error('Method not implemented.');
    }
    generateEntry(body: { type: string; reason: string; branch_id: any; Details: { MovementType: string; SKU: any; Quantity: any; user_id: any; branch_id: any; warehouse_id: any; ProductId: any; Image: any; }[]; }) {
        // const headers = getHeaders();
        return this.http.post(`${apiURL}generate-in`,
            body,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }
        );
        //   throw new Error('Method not implemented.');
    }
    generateExit(body: { type: string; reason: string; branch_id: any; Details: { MovementType: string; SKU: any; Quantity: any; user_id: any; branch_id: any; warehouse_id: any; ProductId: any; Image: any; }[]; }) {
        // const headers = getHeaders();
        return this.http.post(`${apiURL}generate-in`,
            body,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }
        );
        //   throw new Error('Method not implemented.');
    }
    getProductsList4(limit: number, offset: number, text: string, type: string, status: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?limit=${limit}&offset=${offset}&text=${text}&type=${type}&status=${status}`, { headers });
    }
    updateProduct(id: any, updatedItem: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}products/${id}`, updatedItem, { headers });
        //   throw new Error('Method not implemented.');
    }
    updateNotification(id: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}notification/read/${id}`, { headers });
    }
    updateOrderItem(id: any, repair: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order-item/${id}`, repair, { headers });
    }

    downloadPurchaseOrder(body: any): Observable<Blob> {
        return this.http.post(`${apiURL}purchases-by-supplier`,
            body,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }
        );
    }

    addDamageItems(newDamages: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order-items`, newDamages, { headers });
    }
    createFileRecord(body: { FileType: string; FileName: any; FileFormat: any; FileUrl: any; }): any {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}files`, body, { headers });
    }

    updateFileRecord(id: any, body: { FileType: string; FileName: any; FileFormat: any; FileUrl: any; }): any {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}files/${id}`, body, { headers });
    }

    downloadQuote(id: any): Observable<Blob> {
        const headers = getHeaders()
        return this.http.get(`${apiURL}quotes/${id}/pdf`, {
            headers,
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }

    downloadPlan(id: any): Observable<Blob> {
        const headers = getHeaders()
        return this.http.get(`${apiURL}service-order/work-plan/${id}`, {
            headers,
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }
    generateQuote2(quote: { Status: string; CurrencyCode: string; Subtotal: any; Taxes: any; Total: any; Active: boolean; validityDays: any; ServiceOrderId: string | null; QuoteItem: any; }) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}qoutes`, quote, { headers });
    }
    validateSKU(SKU: string) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}validate-sku`, { SKU }, { headers });
    }
    searchAccidentNumber(body: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}accident-data`, body, { headers });
    }
    searchDataBySerie(email: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}vehicle-data`, { search: email }, { headers });
    }
    searchCustomerByEmail(email: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}customer-by-email`, { search: email }, { headers });
    }
    updatePurchaseItem(id: any, element: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}purchase-order-item/${id}`, element, { headers });
    }

    getInventory(offset: any, limit: any, branch: any, search: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}product-inventory?limit=${limit}&offset=${offset}&branch=${branch}&search=${search}`, { headers });
    }

    generateQuote(arg0: { html: string; }) {
        return this.http.post(`${apiURL}service-order/generateQuote`, arg0, {
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }
    downloadFile(id: string) {
        return this.http.get(`${apiURL}service-order/files/${id}`, {
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }

    getAvailableToolsPaginated(
        page: number = 1,
        limit: number = 10,
        search?: string,
        type?: 'tool' | 'machine'
    ): Observable<PaginatedProducts> {
        let params = new HttpParams()
            .set('offset', page.toString())
            .set('limit', limit.toString());

        if (search) {
            params = params.set('search', search);
        }

        if (type) {
            params = params.set('type', type);
        }

        return this.http.get<PaginatedProducts>(
            `${apiURL}available-tools`,
            { params }
        );
    }

    getProductById(id: any) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products/${id}`, { headers });
    }
    getProductsList(offset: number = 0, limit: number = 10, text: string = '') {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?offset=${offset}&limit=${limit}&text=${text}`, { headers });
    }
    getProductsList3(type: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?type=${type}`, { headers });
    }
    getProductsList2(limit: number, offset: number, text: string, type: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?limit=${limit}&offset=${offset}&text=${text}&type=${type}`, { headers });
    }
    uploadFile(formData: FormData) {
        const httpOptions = {
            reportProgress: true,  // Para obtener el progreso de la subida
            observe: 'events' as const, // Aseguramos que observe es de tipo 'events'
            responseType: 'json' as const  // Para asegurarnos de que la respuesta sea JSON
        };

        return this.http.put<any>(`${apiURL}/uploadFile`, formData, {
            reportProgress: httpOptions.reportProgress,
            observe: httpOptions.observe,
            responseType: httpOptions.responseType
        });
    }
    createProduct(formValue: any) {
        const headers = getHeaders();
        return this.http.post<any>(`${apiURL}products`, formValue, { headers });
    }
    updateService(id: any, toUpdate: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order/${id}`, toUpdate, { headers });
    }
    updateServiceFile(id: any, toUpdate: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order-file/${id}`, toUpdate, { headers });
    }

    getUserList(
        limit: number,
        offset: number,
        search: string,
        rol: any,
        column: string,
        order: string
    ): Observable<any> {
        const headers = getHeaders();
        return this.http.get<any>(
            `${apiURL}users?limit=${limit}&offset=${offset}&search=${search}&column=displayName&order=ASC`,
            { headers }
        );
    }

    getUsersByRole(
        limit: number,
        offset: number,
        search: string,
        rol: any,
        column: string,
        order: string
    ): Observable<any> {
        const headers = getHeaders();
        return this.http.get<any>(
            `${apiURL}users/${rol}?limit=${limit}&offset=${offset}&search=${search}&column=displayName&order=ASC`,
            { headers }
        );
    }
    generateWorkPlan(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/work-plan/${ServiceOrderId}`, body, { headers });
    }
    generatePlan(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/repair-plan/${ServiceOrderId}`, body, { headers });
    }
    authService(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/auth/${ServiceOrderId}`, body, { headers });
    }
    updateRequisition(id: string | null, data: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}requisition/${id}`, data, { headers });
    }
    updatePurchaseOrder(id: string | null, data: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}purchase-order/${id}`, data, { headers });
    }
    rejectPurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.delete<any>(`${apiURL}purchase-order/${id}`, { headers });
    }
    rejectRequisition(id: any) {
        const headers = getHeaders()
        return this.http.delete<any>(`${apiURL}requisition/${id}`, { headers });
    }
    getRequisition(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}requisition/${id}`, { headers });
    }
    getPurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-order/${id}`, { headers });
    }
    getAllPurchaseOrders2(offset: any, limit: any, type: string) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-orders?limit=${limit}&offset=${offset}&type=${type}&status=Autorizada`, { headers });
    }
    getAllRequisitions2(offset: any, limit: any, status: string, search: string, sDate: string, eDate: string) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}requisitions?limit=${limit}&offset=${offset}&Status=${status}&search=${search}&StartDate=${sDate}&EndDate=${eDate}`, { headers });
    }
    getAllRequisitions(offset: any, limit: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}requisitions?limit=${limit}&offset=${offset}&status=Autorizada`, { headers });
    }
    getAllPurchaseOrders(offset: any, limit: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-orders?limit=${limit}&offset=${offset}&status=Autorizada`, { headers });
    }
    getAllPurchase2(offset: any, limit: any, status: string, search: string, sDate: string, eDate: string) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-orders?limit=${limit}&offset=${offset}&Status=${status}&search=${search}&StartDate=${sDate}&EndDate=${eDate}`, { headers });
    }
    getPurchasesByOrder(id: string | null): Observable<any> {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}/purchase-order`, { headers });
    }
    generatePurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/${id}/purchase-order`, null, { headers });
    }
    getOrderByUUID(id: string | null) {
        return this.http.get<any>(`${apiURL}service-by-uuid/${id}`);
    }
    getOrderById(id: string | null) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}`, { headers });
    }
    saveDamageSurvey(body: { ServiceOrderItems: any[]; }, id: string | null) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/${id}/levantamiento`, body, { headers });
    }
    getAllOrders(limit: any, offset: any) {
        return this.http.get<any[]>(`${apiURL}service-order?limit=${limit}&offset=${offset}`);
    }

    getAllOrders2(limit: any, offset: any, search: string, status: string, sDate: string, eDate: string) {
        return this.http.get<any>(`${apiURL}service-order?limit=${limit}&offset=${offset}&search=${search}&status=${status}&sDate=${sDate}&eDate=${eDate}`);
    }

    constructor(private http: HttpClient) { }

    obtenerFormWithAnswers(idForm: any): Observable<any[]> {
        return this.http.get<any[]>(`${apiURL}form/${idForm}`);
    }

    obtenerFormSatisfaction(idForm: any): Observable<any[]> {
        return this.http.get<any[]>(`${apiURL}satisfaction/4`);
    }

    saveServiceOrder(body: any) {
        return this.http.post<any[]>(`${apiURL}service-order`, body);
    }
    saverOrderAnswers(body: { FormId: number; UserId?: any; Answers: any; ServiceOrderId: any; }) {
        return this.http.post<any[]>(`${apiURL}forms/answers`, body);
    }
    obtenerFormByOrder(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}/inventario`, { headers });
    }

    getAllInventoryMovements(): Observable<any[]> {
        const headers = getHeaders()
        return this.http.get<any[]>(`${apiURL}inventory-movements`, { headers });
    }
}
